import * as THREE from 'three'
import Experience from "../Experience";

export default class Fox {
    constructor(){
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.debug = this.experience.debug

        //debug
        if(this.debug.active){
            // this.debugFolder = this.debug.ui.addFolder('LEDA')
        }

        //setup
        this.resource =  this.resources.items.migros

        this.setModel()
        this.setMaterials()
        // this.setAnimation()
    }

    setModel(){
        this.model = this.resource.scene
        this.model.scale.set(0.01,0.01,0.01)
        this.model.position.x = 0.75
        this.model.rotation.y = Math.PI * 0.5
        this.model.receiveShadow = true
        this.scene.add(this.model)

        //Model needs normal tags for proper render lighting
        this.model.traverse((child)=>{
            if(child.isMesh){
               child.material.metalness = 0
               child.material.roughness = 1
                child.castShadow =true
                child.receiveShadow = true
            }
            
        })
    }

    setMaterials(){
        //materials
        const migros_mat = new THREE.MeshStandardMaterial({color:'#D66C36'})

        //objects
        const migros = this.model.getObjectByName("migros_m")
        
        //assign materials
        migros.material = migros_mat
        
    }

    setAnimation(){
        this.animation = {}
        this.animation.mixer = new THREE.AnimationMixer(this.model)
        this.animation.actions = {}

        this.animation.actions.idle = this.animation.mixer.clipAction(this.resource.animations[0])
        this.animation.actions.walking = this.animation.mixer.clipAction(this.resource.animations[1])
        this.animation.actions.running = this.animation.mixer.clipAction(this.resource.animations[2])
        
        this.animation.actions.current = this.animation.actions.idle
        this.animation.actions.current.play()

        this.animation.play = (name) => {
            const newAction = this.animation.actions[name]
            const oldAction = this.animation.actions.current
            newAction.reset()
            newAction.play()
            newAction.crossFadeFrom(oldAction,1)

            this.animation.actions.current = newAction
        }

        //debug
        if(this.debug.active){
            const debugObject ={
                playIdle: () => { this.animation.play('idle')},
                playWalking: () => { this.animation.play('walking')},
                playRunning: () => { this.animation.play('running')}
            }

            this.debugFolder.add(debugObject, 'playIdle')
            this.debugFolder.add(debugObject, 'playWalking')
            this.debugFolder.add(debugObject, 'playRunning')
        }
    }

    update(){
        //mixer handles animation in seconds and delta is in milliseconds
        // this.animation.mixer.update(this.time.delta * 0.001)
    }
}