import * as THREE from 'three'
import Experience from "../Experience";

export default class Floor {
    constructor(){
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources

        //setup
        this.setGeometry()
        this.setTextures()
        this.setMaterials()
        this.setMesh()
    }

    setGeometry(){
        this.geometry  = new THREE.CircleGeometry(25, 64)
        

    }
    setTextures(){
        this.textures = {}

        //Color Texture
        this.textures.color = this.resources.items.grassColorTexture
        this.textures.color.encoding = THREE.sRGBEncoding
        this.textures.color.repeat.set(1.5, 1.5)
        this.textures.color.wrapS = THREE.RepeatWrapping
        this.textures.color.wrapT = THREE.RepeatWrapping
        
        //Normal Texture
        this.textures.normal = this.resources.items.grassNormalTexture
        
        this.textures.normal.repeat.set(1.5, 1.5)
        this.textures.normal.wrapS = THREE.RepeatWrapping
        this.textures.normal.wrapT = THREE.RepeatWrapping

    }
    setMaterials(){
        this.material = new THREE.MeshStandardMaterial({
            map:this.textures.color,
            normalMap:this.textures.normal,
        })

    }
    setMesh(){
        this.mesh = new THREE.Mesh(this.geometry,this.material)
        this.mesh.rotation.x = - Math.PI * 0.5
        // this.mesh.position.y = -0.6
        this.mesh.receiveShadow = true
        this.scene.add(this.mesh) 

    }

    
}