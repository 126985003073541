import * as THREE from 'three'
import Experience from "../Experience";
export default class Environment {

    constructor(){
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.debug = this.experience.debug
        // this.daylight = false


        //debug
        if(this.debug.active){
            // this.debugFolder = this.debug.ui.addFolder('Environment')
        }
        
     
        this.setEnvironmentMap()
        
     
    }

    

    setEnvironmentMap(){
        this.environmentMap = {}
        this.environmentMap.intensity = 0
        this.environmentMap.texture = this.resources.items.environmentMapTexture
        this.environmentMap.texture.encoding = THREE.sRGBEncoding

        this.scene.environment = this.environmentMap.texture
        

        this.environmentMap.updateMaterials = () =>{
            this.scene.traverse((child) =>{
                if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial){
                    child.material.envMap = this.environmentMap.texture
                    child.material.envMapIntensity = this.environmentMap.intensity
                    child.material.needsUpdate = true
                }
            })

        }

        this.environmentMap.updateMaterials()
    }

    // isDaylight(){
        
    //     if(!this.daylight){
    //         this.environmentMap.intensity = 0.5
    //         this.environmentMap.updateMaterials()
    //         this.keyLight.intensity = 0
    //         this.fillLight.intensity = 0
    //         this.daylight = true
    //     } else {
    //         this.environmentMap.intensity = 0.0
    //         this.environmentMap.updateMaterials()
    //         this.keyLight.intensity = 10
    //         this.fillLight.intensity = 5
    //         this.daylight = false
    //     }

    // }

   


}