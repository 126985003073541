
import Experience from "../Experience";
import Environment from './Environment';
import Floor from './Floor';
import LEDA from './LEDA';
import Lighting from "./Lighting";

export default class World{
    constructor(){
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.lighting = new Lighting()
      

        this.resources.on('ready', ()=>{
            
            this.floor = new Floor()
            this.leda = new LEDA()
            this.environment = new Environment()
        })

    
     
    }

    update(){
        if(this.leda)
            this.leda.update()
    }
}