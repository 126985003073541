import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { MathUtils } from "three";
import * as THREE from 'three'
import Experience from "../Experience";

export default class Interaction {

    constructor(){
        this.experience = new Experience()
        this.camera = this.experience.camera.instance
        this.controls = this.experience.camera.controls
        console.log(this.camera);
        this.canvas = document.querySelector('.webgl')
        
        this.interactionSetup()
    }

    interactionSetup(){
        this.showButton = document.querySelector('[data-show]')
        this.exploreButton = document.querySelector('[data-explore]')
        this.target = new THREE.Vector3(0,10,0)

        this.showButton.addEventListener('click', (event)=>{
            event.preventDefault()
            
            this.toInteractionZone()
        })

        this.exploreButton.addEventListener('click', (event)=>{
            event.preventDefault()
           
            this.toExploreMode()

        })
       
    }
    
    toInteractionZone(){
        gsap.to(this.camera.position,{x:0, y:2, z:7, duration: 1, onComplete: ()=>{
            this.controls.enabled = false
            this.canvas.style.cursor = 'auto'
            
        },})

       
    }
    toExploreMode(){
      
        
        gsap.to(this.camera.position,{x:12, y:10, z:12, duration: 1, onComplete: ()=>{
            this.controls.enabled = true
            this.canvas.style.cursor = 'url("/orbit-cursor.png"), auto'
            
        }})
    }

}
   