import * as THREE from 'three'
import Sizes from "./Utils/Sizes"
import Time from "./Utils/Time"
import Camera from './Camera'
import Renderer from './Renderer'
import World from './World/World'
import CameraAnimation from './Animation/CameraAnimation'
import LightAnimation from './Animation/LightAnimation'
import Interaction from './Interaction/Interaction'
import Resources from './Utils/Resources'
import assets from './assets'
import Debug from './Utils/Debug'

let instance = null


export default class Experience {
    constructor(canvas){
        //turns this class into singleton: it's instantiated once, and for next times it returns the first instance (copy)
        if(instance){
            return instance
        }

        instance = this


        //Global access
        window.experience = this

        //Options
        this.canvas = canvas

        //Setup
        this.debug = new Debug()
        this.sizes = new Sizes()
        this.time =  new Time()
        this.scene = new THREE.Scene()
        this.resources = new Resources(assets)
        this.camera =  new Camera()
        this.renderer = new Renderer()
        this.world =  new World()
        // this.cameraAnimation = new CameraAnimation()
        // this.lightAnimation = new LightAnimation()
        this.interaction = new Interaction()


        //Sizes resize event
        this.sizes.on('resize', ()=>{
            this.resize()

        })

        //Time tick event
        this.time.on('tick', ()=>{
            this.update()
        })


    }

    resize(){
        this.camera.resize()
        this.renderer.resize()

    }

    update(){
        this.camera.update()
        this.world.update()
        this.renderer.update()
    }

    destroy(){
        //it's important to remove what it's not used
        //usually is better to have a destro method on each class
        //removes event listeners
        this.sizes.off('resize')
        this.time.off('tick')

        //traverse scene and removes whatever has a dispose method
        this.scene.traverse((child)=>{
            if(child instanceof THREE.Mesh){
                child.geometry.dispose()

                for(const key in child.material){
                    const value = child.material[key]

                    if(value && typeof value.dispose === 'function')
                    {
                        value.dispose()
                    }
                }
            }
            
        })

        //removes camera and renderer
        this.camera.controls.dispose()
        this.renderer.instance.dispose()

        //if using post-processing, the effect composer, wegbl targe and all passes need to be removed


        //removes debug interface
        if(this.debug.active)
            this.debug.ui.destroy()
    }

}