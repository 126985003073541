import * as THREE from 'three'
import Experience from "../Experience";

export default class Lighting {
    constructor(){
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.debug = this.experience.debug
        this.daylight = false
       

        //debug
        if(this.debug.active){
            this.debugFolder = this.debug.ui.addFolder('Lighting')
        }
        
        // this.setSunLight()
        // this.setEnvironmentMap()
        
        // this.setAmbientLight()
        this.setPointLights()
        this.setDebugSettings()

    }
    setSunLight(){
        this.sunLight = new THREE.DirectionalLight('#ffffff', 4)
        this.sunLight.castShadow = true
        this.sunLight.shadow.camera.far = 150
        this.sunLight.shadow.mapSize.set(1024, 1024)
        this.sunLight.shadow.normalBias = 0.05
        this.sunLight.position.set(3.5, 2, - 1.25)
        this.scene.add(this.sunLight)

        //debug
        if(this.debug.active){
            this.debugFolder
                .add(this.sunLight, 'intensity')
                .name('sunLight Intensity')
                .min(0)
                .max(10)
                .step(0.001)

            this.debugFolder
                .add(this.sunLight.position, 'x')
                .name('sunLight X')
                .min(0)
                .max(5)
                .step(0.001)

            this.debugFolder
                .add(this.sunLight.position, 'y')
                .name('sunLight Y')
                .min(0)
                .max(5)
                .step(0.001)

            this.debugFolder
                .add(this.sunLight.position, 'z')
                .name('sunLight Z')
                .min(0)
                .max(5)
                .step(0.001)
        }
    }

    setPointLights(){
        //Key Light
    
        this.keyLight = new THREE.PointLight('#0000ff', 10,13)
        this.keyLight.position.set(5, 10, 3)
        this.keyLight.castShadow = true
      
        this.keyLight.shadow.mapSize.set(1024, 1024)
        this.keyLight.shadow.normalBias = 0.05
        this.scene.add(this.keyLight)

        

        //Fill Light
        this.fillLight = new THREE.PointLight('#ff0000', 2,20)
        this.fillLight.position.set(-5, 10, 3)
        this.fillLight.castShadow = true
      
        this.fillLight.shadow.mapSize.set(1024, 1024)
        this.fillLight.shadow.normalBias = 0.05
        this.scene.add(this.fillLight)

        if(this.debug.active){

            //key light helper
            this.helper = new THREE.PointLightHelper(this.keyLight, 1)
            this.scene.add(this.helper)

            //fill light helper
            this.helper = new THREE.PointLightHelper(this.fillLight, 1)
            this.scene.add(this.helper)

        }

        

    }

    setAmbientLight(){
        this.ambientLight = new THREE.AmbientLight('#0000ff', 0.5)
        // this.ambientLight.position.set(0, 10, 0)
        // this.ambientLight.castShadow = true
      
        // this.ambientLight.shadow.mapSize.set(1024, 1024)
        // this.ambientLight.shadow.normalBias = 0.05
        this.scene.add(this.ambientLight)

        // this.helper = new THREE.PointLightHelper(this.pointLight, 1)
        // this.scene.add(this.helper)
    }
    setDebugSettings(){
        if(this.debug.active){
            const keyLightFolder = this.debugFolder.addFolder('Key Light')
            const fillLightFolder = this.debugFolder.addFolder('Fill Light')
            

            const opt = {
                    keyColor: this.keyLight.color,
                    fillColor: this.fillLight.color,
                    day:false,
                    daylight:()=> this.isDaylight()
                   
            }

            // this.debugFolder
            //     .add(opt, 'daylight')
            //     .name('Daylight')

          

            keyLightFolder
                .addColor(opt, 'keyColor')
                .onFinishChange(()=>{
                    this.keyLight.color = opt.keyColor
                })
            
            keyLightFolder
                .add(this.keyLight, 'intensity')
                .name('Key Intensity')
                .min(0)
                .max(200)
                .step(1)

            keyLightFolder
                .add(this.keyLight, 'distance')
                .name('Key Distance')
                .min(0)
                .max(100)
                .step(0.1)
            
            fillLightFolder
                .addColor(opt, 'fillColor')
                .onFinishChange(()=>{
                    this.fillLight.color = opt.fillColor
                })
            
            fillLightFolder
                .add(this.fillLight, 'intensity')
                .name('Fill Intensity')
                .min(0)
                .max(200)
                .step(1)

            fillLightFolder
                .add(this.fillLight, 'distance')
                .name('Fill Distance')
                .min(0)
                .max(100)
                .step(0.1)
        }
    }

}