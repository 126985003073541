import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import Experience from "./Experience";

export default class Camera {
    constructor(){

        this.experience =  new Experience()
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.canvas = this.experience.canvas
        this.controls = null
        this.target = null

        this.setInstance()
        this.setOrbitControls()

    }

    setInstance(){
        this.instance = new THREE.PerspectiveCamera(
            55, 
            this.sizes.width / this.sizes.height,
            0.1,
            100
            )
        
            this.instance.position.set(12,10,12)
            this.instance.lookAt(0,3,0)
            
            this.scene.add(this.instance)
    }

    setOrbitControls(){
        this.controls = new OrbitControls(this.instance, this.canvas)
        this.controls.enableDamping = true
       
        this.controls.maxPolarAngle = Math.PI * 0.55

        this.target = new THREE.Vector3(0,3,0)

        this.controls.target = this.target
  
        

    }

    resize(){
        this.instance.aspect = this.sizes.width / this.sizes.height
        this.instance.updateProjectionMatrix()
    }

    update(){
        this.controls.update()
        
    }
}