import * as THREE from 'three'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { UnrealBloomPass} from 'three/examples/jsm/postprocessing/UnrealBloomPass'
import { GammaCorrectionShader} from 'three/examples/jsm/shaders/GammaCorrectionShader'
import { ShaderPass} from 'three/examples/jsm/postprocessing/ShaderPass'
import Experience from "./Experience";

export default class Renderer {
    constructor(){
        this.experience = new Experience()
        this.canvas = this.experience.canvas
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.camera = this.experience.camera
        this.debug = this.experience.debug
        this.effectComposer 

         //debug
         if(this.debug.active){
            
        }

        this.setInstance()
        this.setPostProcessing()
        this.setDebugSettings()

      
    }
    setInstance(){
        this.instance = new THREE.WebGLRenderer({
            canvas: this.canvas,
            antialias: true
        })
        this.instance.physicallyCorrectLights = true
        this.instance.outputEncoding = THREE.sRGBEncoding
        this.instance.toneMapping = THREE.ACESFilmicToneMapping
        this.instance.toneMappingExposure = 1.75
        this.instance.shadowMap.enabled = true
        this.instance.shadowMap.type = THREE.PCFSoftShadowMap
        this.instance.setClearColor('#000000')
        this.instance.setSize(this.sizes.width, this.sizes.height)
        this.instance.setPixelRatio(this.sizes.pixelRatio)  
    }

    setPostProcessing(){
        this.effectComposer = new EffectComposer(this.instance)
        this.effectComposer.setPixelRatio(this.sizes.pixelRatio)
        this.effectComposer.setSize(this.sizes.width, this.sizes.height)

        const renderPass = new RenderPass(this.scene, this.camera.instance)
        this.effectComposer.addPass(renderPass)

        //Bloom pass
        this.unrealBloomPass = new UnrealBloomPass()
        this.unrealBloomPass.strength = 0.5
        this.unrealBloomPass.radius = 0.2
        this.unrealBloomPass.threshold = 0.02

        this.unrealBloomPass.enabled = true
     

        this.effectComposer.addPass(this.unrealBloomPass)

        //color fixing
        const gamaCorrectionPass = new ShaderPass(GammaCorrectionShader)
        this.effectComposer.addPass(gamaCorrectionPass)
       
    }

    setDebugSettings(){
        
        if(this.debug.active){
        this.debugFolder = this.debug.ui.addFolder('Bloom')
        this.debugFolder
            .add(this.unrealBloomPass, 'enabled')
        
        this.debugFolder
            .add(this.unrealBloomPass, 'strength')
            .name('Strength')
            .min(0.1)
            .max(2)
            .step(0.001)

        this.debugFolder
            .add(this.unrealBloomPass, 'radius')
            .name('Radius')
            .min(0.001)
            .max(1)
            .step(0.001)

        this.debugFolder
            .add(this.unrealBloomPass, 'threshold')
            .name('Threshold')
            .min(0)
            .max(0.1)
            .step(0.001)


        }
      

    }

    resize(){
        this.instance.setSize(this.sizes.width, this.sizes.height)
        this.instance.setPixelRatio(this.sizes.pixelRatio)
        this.effectComposer.setSize(this.sizes.width, this.sizes.height)
        this.effectComposer.setPixelRatio(this.sizes.pixelRatio)
    }

    update(){
        this.instance.render(this.scene, this.camera.instance)
        // this.effectComposer.render()
        
    }


}